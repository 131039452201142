// COLORS
$color-primary: #02010a;
$color-light: white;
$color-parallax: #428998;

// New color scheme
$color-new-1: rgb(6, 83, 104);
$color-new-2: rgb(9, 28, 92);
$color-new-3: rgb(24, 61, 125);
$color-icons: #372343;
$color-icons-hover: #201228;

// Page layout
$section-margin: 10rem;
$max-width: 1100px;
$margin-sides-1: 8rem;
$margin-sides-2: 4rem;
$margin-sides-3: 0rem;

// font sizes
$font-navbar: 1rem;

// Reference to add animations:
// https://www.sliderrevolution.com/resources/css-border-animation/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // background-color: rgba(0, 0, 0, 0.1);
}

html {
  scroll-behavior: smooth;
}

Body {
  background: $color-parallax;
  font-family: "Roboto", sans-serif;
}

// Typography
h1 {
  font-size: 4.5rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 3rem;
  font-weight: 300;
}

h4 {
  font-size: 2rem;
  font-weight: 200;
  color: $color-light;
}

p {
  font-size: 1.5rem;
  color: rgba($color-light, 0.8);
  text-align: left;
}

small,
.text_small {
  font-size: 0.667rem;
}

.App {
  align-items: center;
  background: $color-parallax;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.Blur {
  backdrop-filter: blur(6px);
  height: 100%;
  position: absolute;
  width: 100vw;
  z-index: 9;
}

// links

// Navbar
.Navbar {
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
  display: flex;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  transition: all 0.6s ease;
  width: 100%;
  z-index: 99;

  // Controlled via media query below

  &--up {
    transform: translateY(0%);
  }
  &--down {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    transform: translateY(-100%);
  }

  &__icon {
    color: $color-icons;
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: auto;
    transition: all 0.3s ease;
  }
  &__icon:hover {
    cursor: pointer;
    color: $color-icons-hover;
  }

  &__ul {
    display: flex;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: right;
    margin: 1rem;
  }

  &__li {
    background: $color-icons;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    list-style: none;
    padding: 0.5rem 1.5rem;
    transition: background 0.2s ease;

    &__link {
      color: white;
      font-size: $font-navbar;
      font-weight: 400;
      text-decoration: none;
    }
  }

  &__li:hover {
    background: $color-icons-hover;
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  .Navbar {
    padding: 0;

    &__icon {
      display: none;
    }

    &__ul {
      align-items: center;
      flex-grow: 1;
      gap: 0.4rem;
      justify-content: center;
      margin: 0.5rem 0.3rem;
    }

    &__li {
      flex-grow: 1;
      width: 40%;
    }
  }
}

.HeroStatic {
  color: white;
  margin-bottom: 30rem;
  margin-top: 20rem;
  z-index: 9;

  &__title {
    text-shadow: -1px 2px 6px $color-icons-hover;
  }

  &__subtitle {
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    text-shadow: -1px 1px 2px $color-icons-hover;
  }

  &__link {
    color: $color-primary;
    font-size: 5rem;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  &__link:hover {
    font-size: 6rem;
  }
}

@media screen and (max-width: 700px) {
  .HeroStatic {
    &__title {
      font-size: 2.9rem;
    }
    &__subtitle {
      font-size: 1.9rem;
    }
  }
}

.SectionContainer {
  // background: rgba($color-new-1, 0.3);
  // box-shadow: 0 25px 40px rgba(0, 0, 0, 0.4);
  margin-bottom: $section-margin;
  margin-left: $margin-sides-1;
  margin-right: $margin-sides-1;
  max-width: $max-width;
  padding: 2rem;
  width: 100%;
  z-index: 1;
}
.SectionHeading {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  &__number {
    color: $color-light;
    font-size: 1.2rem;
  }

  &__title {
    color: $color-light;
    margin-left: 2rem;
    white-space: nowrap;
  }
  &__line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    height: 80%;
    margin-left: 20px;
    width: 100%;
  }

  &__paragraph {
    color: rgba(white, 0.7);
    font-weight: 300;
    margin-bottom: 2rem;
    text-align: left;
  }
}

@media screen and (max-width: 700px) {
  .SectionHeading {
    &__title {
      font-size: 2rem;
    }
  }
}

// About Me
#About {
  margin-top: 60rem;
}

// Skills
.Skills {
  width: 100%;
  z-index: 99;
  &__ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
  &__li {
    // color: $color-new-1;
    flex-grow: 1;
    font-size: 5rem;
  }
}

// Projects
.Project {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    &__header {
      align-self: left;
      color: $color-light;
      width: 100%;
    }

    &__img-container {
      position: relative;
    }

    &__img {
      width: 500px;

      &-hover {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        color: rgba(255, 255, 255, 0);
        display: flex;
        font-size: 1rem;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        text-decoration: none;
        top: 0;
        transition: all 0.2s ease;
        width: 100%;
      }

      &-hover:hover {
        background: rgba(255, 255, 255, 0.8);
        color: $color-icons-hover;
        font-size: 2rem;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .Project {
    &__container {
      &__img {
        width: 100%;
      }
    }
  }
}

// Contact Me
.Contact {
  &__logo {
    font-size: 2rem;
  }

  &__container {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
  }

  &__header {
    color: $color-light;
    margin-bottom: 2rem;
  }

  &__paragraph {
    color: $color-light;
    margin-bottom: 3rem;
  }

  &__email {
    background: $color-icons;
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    color: $color-light;
    font-size: 1.5rem;
    padding: 1rem 2.5rem;
    text-decoration: none;
    transition: all 0.2s ease;
  }

  &__email:hover {
    background: $color-icons-hover;
  }
}

@media screen and (max-width: 700px) {
  .Contact {
    &__logo {
      font-size: 1.3rem;
    }
    &__email {
      font-size: 1.3rem;
    }
  }
}

// Footer
.Footer {
  align-items: center;
  background: $color-new-1;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: center;
  width: 100%;
  z-index: 1;

  &__a {
    color: rgba($color-light, 0.5);
    font-weight: 300;
    text-decoration: none;
    transition: all 0.2s ease;
  }

  &__a:hover {
    color: rgba($color-light, 1);
  }
}

// Side Links
.SideLinks {
  &__email {
    align-items: center;
    bottom: (25px);
    display: flex;
    flex-direction: row;
    position: fixed;
    right: 30px;
    writing-mode: vertical-rl;
    z-index: 1;

    &__a {
      color: rgba($color-light, 0.5);
      font-size: 1.25rem;
      letter-spacing: 0.1em;
      text-decoration: none;
      transition: all 0.2s ease;
    }

    &__a:hover {
      color: rgba($color-light, 0.9);
    }

    &__line {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      color: white;
      height: 100px;
      margin-top: 15px;
    }
  }
}

// Media Queries
@media screen and (max-width: 1100px) {
  .SideLinks__email {
    right: 15px;
  }

  h1 {
    font-size: 4.25rem;
  }

  h2 {
    font-size: 3.25rem;
  }

  h3 {
    font-size: 2.75rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  p {
    font-size: 1.25rem;
  }
}

// Mobile formatting
@media screen and (max-width: 780px) {
  .SideLinks {
    display: none;
  }
}

// Background Parallax
.Parallax__img {
  height: 100vh;
  left: 0;
  min-width: 1100px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &__color {
    background: $color-parallax;
    height: 20000px;
    top: 100vh;
  }
}

@media screen and (max-width: 600px) {
  .Parallax__img {
    left: -300px;
  }
}

// Project page
.projectPage {
  background: $color-parallax;

  &__backButton {
    color: $color-icons;
    font-size: 3rem;
    margin-left: 2rem;
    margin-top: 1rem;
    transition: all 0.2s ease;
  }

  &__backButton:hover {
    color: $color-icons-hover;
    transform: scale(1.1);
  }

  &__img {
    margin: 2rem;
    object-fit: contain;
    width: 40%;
  }

  &__container {
    display: flex;
  }

  &__description {
    &-container {
      margin: 2rem;
    }

    &__ul {
      color: $color-icons-hover;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: left;
      list-style: none;
      text-transform: uppercase;
    }

    &__title {
      color: white;
      font-weight: 200;
      margin-top: 1rem;
    }

    &__p {
      color: rgba(white, 0.7);
      font-weight: 300;
      margin-bottom: 2rem;
      margin-bottom: 2rem;
      margin-top: 1.7rem;
      text-align: left;
    }

    &__links {
      display: flex;

      &__link {
        border-radius: 20px;
        color: $color-icons-hover;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 0.5rem;
        text-decoration: none;
        transition: all 0.3s ease;
      }
      &__link:hover {
        font-size: 1.8rem;
      }
    }
  }

  &__img-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    margin-top: 4rem;
    padding-bottom: 2rem;

    &__img {
      object-fit: contain;
      width: 70%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .projectPage {
    &__description {
      &__title {
        font-size: 3rem;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .projectPage {
    &__description-container {
      margin-left: 5rem;
      margin-right: 5rem;
    }
    &__container {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: 0;
    }
    &__img {
      object-fit: contain;
      width: 70%;
    }
    &__img-container {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  .projectPage {
    &__description {
      &__ul {
        justify-content: center;
      }
      &-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: right;
        margin: 0.5rem;
      }
      &__p {
        margin: 1rem;
      }
    }

    &__img {
      width: 90%;
    }

    &__img-container {
      &__img {
        width: 90%;
      }
    }
  }
}

/* ==========================================================================
Animation System by Neale Van Fleet from Rogue Amoeba
========================================================================== */
.animate {
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Fade In */
.animate.fade {
  animation-name: animate-fade;
  animation-timing-function: ease;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Pop In */
.animate.pop {
  animation-name: animate-pop;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Blur In */
.animate.blur {
  animation-name: animate-blur;
  animation-timing-function: ease;
}

@keyframes animate-blur {
  0% {
    opacity: 0;
    filter: blur(15px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

/* Grow In */
.animate.grow {
  animation-name: animate-grow;
}

@keyframes animate-grow {
  0% {
    opacity: 0;
    transform: scale(1, 0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Splat In */
.animate.splat {
  animation-name: animate-splat;
}

@keyframes animate-splat {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(20deg) translate(0, -30px);
  }
  70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg);
  }
  85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0) translate(0, 0);
  }
}

/* Roll In */
.animate.roll {
  animation-name: animate-roll;
}

@keyframes animate-roll {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(360deg);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0deg);
  }
}

/* Flip In */
.animate.flip {
  animation-name: animate-flip;
  transform-style: preserve-3d;
  perspective: 1000px;
}

@keyframes animate-flip {
  0% {
    opacity: 0;
    transform: rotateX(-120deg) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg) scale(1, 1);
  }
}

/* Spin In */
.animate.spin {
  animation-name: animate-spin;
  transform-style: preserve-3d;
  perspective: 1000px;
}

@keyframes animate-spin {
  0% {
    opacity: 0;
    transform: rotateY(-120deg) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) scale(1, 1);
  }
}

/* Slide In */
.animate.slide {
  animation-name: animate-slide;
}

@keyframes animate-slide {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Drop In */
.animate.drop {
  animation-name: animate-drop;
  animation-timing-function: cubic-bezier(0.77, 0.14, 0.91, 1.25);
}

@keyframes animate-drop {
  0% {
    opacity: 0;
    transform: translate(0, -300px) scale(0.9, 1.1);
  }
  95% {
    opacity: 1;
    transform: translate(0, 0) scale(0.9, 1.1);
  }
  96% {
    opacity: 1;
    transform: translate(10px, 0) scale(1.2, 0.9);
  }
  97% {
    opacity: 1;
    transform: translate(-10px, 0) scale(1.2, 0.9);
  }
  98% {
    opacity: 1;
    transform: translate(5px, 0) scale(1.1, 0.9);
  }
  99% {
    opacity: 1;
    transform: translate(-5px, 0) scale(1.1, 0.9);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1, 1);
  }
}

/* Animation Delays */
.delay-1 {
  animation-delay: 0.2s;
}
.delay-2 {
  animation-delay: 0.3s;
}
.delay-3 {
  animation-delay: 0.4s;
}
.delay-4 {
  animation-delay: 0.5s;
}
.delay-5 {
  animation-delay: 0.6s;
}
.delay-6 {
  animation-delay: 0.7s;
}
.delay-7 {
  animation-delay: 0.8s;
}
.delay-8 {
  animation-delay: 0.9s;
}
.delay-9 {
  animation-delay: 1s;
}
.delay-10 {
  animation-delay: 1.1s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .animate {
    animation: none !important;
  }
}
